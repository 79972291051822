// Generated by Framer (1a6990e)

import { addFonts, cx, CycleVariantState, Image, Link, useConstant, useIsOnFramerCanvas, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./pZ3goURdk-0.js";

const cycleOrder = ["dnZxmIhKx"];

const serializationHash = "framer-XohVR"

const variantClassNames = {dnZxmIhKx: "framer-v-7bxki2"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const valuesByLocaleId = {eWWczOETO: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "dnZxmIhKx", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const isOnCanvas = useIsOnFramerCanvas()

const initialVariant = useConstant(() => variant)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><Link href={{webPageId: "M2FDSrOUF"}} openInNewTab={false}><Image {...restProps} animate={variants} as={"a"} background={{alt: "", fit: "fit", intrinsicHeight: 512, intrinsicWidth: 1900, pixelHeight: 512, pixelWidth: 1900, positionX: "left", positionY: "center", src: "https://framerusercontent.com/images/mSYckrbDIKtmkdm7D0AvOCAUE.svg"}} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-7bxki2", className, classNames)} framer-1dil9hw`} data-framer-name={"Variant 1"} initial={isOnCanvas ? variant : initialVariant } layoutDependency={layoutDependency} layoutId={"dnZxmIhKx"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}/></Link></Transition>
</LayoutGroup>)

});

const css = [".framer-XohVR[data-border=\"true\"]::after, .framer-XohVR [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-XohVR.framer-1dil9hw, .framer-XohVR .framer-1dil9hw { display: block; }", ".framer-XohVR.framer-7bxki2 { height: 24px; position: relative; text-decoration: none; width: 90px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 90
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerpZ3goURdk: React.ComponentType<Props> = withCSS(Component, css, "framer-XohVR") as typeof Component;
export default FramerpZ3goURdk;

FramerpZ3goURdk.displayName = "MainLogo";

FramerpZ3goURdk.defaultProps = {height: 24, width: 90};

addFonts(FramerpZ3goURdk, [])